<template>
  <v-dialog v-model="isOpen" persistent :width="336">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title>
          <v-list-item three-line class="pa-0">
            <v-list-item-content>
              <v-list-item-title class="headline ma-0 pa-0">
                Wybierz rodzaj paczki
              </v-list-item-title>
              <v-list-item-subtitle class="mt-n4">
                Wybraną paczkę można dowolnie modyfikować
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 px-7 grey--text text--darken-4">
        <v-row justify="center" align="center">
          <v-col v-for="(item, index) in packageTypes" :key="index" cols="6" class="px-4">
            <v-btn dark @click="submit(item.value, index)" style="height: fit-content" class="pa-0">
              <v-row no-gutters class="square-120">
                <v-col cols="12" style="height: 90px">
                  <v-icon
                    :size="item.size || 40"
                    :class="`mt-${Math.floor((90 - (item.size || 40)) / 4)}`"
                  >
                    {{ item.icon || 'mdi-package-variant' }}
                  </v-icon>
                </v-col>
                <v-col cols="12">
                  <span class="one-liner-text">
                    {{ item.text }}
                    <v-tooltip bottom max-width="260" v-if="item.tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" small> mdi-information </v-icon>
                      </template>
                      <span>
                        {{ item.tooltip }}
                      </span>
                    </v-tooltip>
                  </span>

                  <span class="one-liner-text mt-n1" v-if="item.showDimensions">
                    {{
                      item.value.height +
                      ' &times; ' +
                      item.value.width +
                      ' &times; ' +
                      item.value.depth
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';

  export default {
    name: 'ChoosePackageSizeDialog',
    components: {},
    props: {},
    data: () => ({
      isOpen: false,

      disableExtraPackages: false,
      variantType: 'upholstered_furniture',
      packageSize: {
        chairs: [
          {
            text: 'Normalna',
            value: {
              height: '100',
              width: '55',
              depth: '50',
              dimensions_unit: 'cm',
              weight: '10',
              measure_unit: 'kg',
              cardboard_boxes: true,
            },
          },
          // {
          //   text: 'Dodatki',
          //   value: {
          //     height: '0',
          //     width: '0',
          //     depth: '0',
          //     dimensions_unit: 'cm',
          //     weight: '0',
          //     measure_unit: 'kg',
          //     isExtra: true,
          //   },
          //   size: 40,
          //   icon: 'mdi-package-variant-closed',
          //   tooltip: 'Dodatki nie wymagają podania wymiarów'
          // },
        ],
        tables: [
          {
            text: 'do 120 cm',
            value: {
              height: '82',
              width: '122',
              depth: '25',
              dimensions_unit: 'cm',
              weight: '32',
              measure_unit: 'kg',
            },
            size: 40,
          },
          {
            text: 'do 160 cm',
            value: {
              height: '82',
              width: '162',
              depth: '25',
              dimensions_unit: 'cm',
              weight: '55',
              measure_unit: 'kg',
            },
            size: 56,
          },
          {
            text: 'do 200 cm',
            value: {
              height: '82',
              width: '202',
              depth: '25',
              dimensions_unit: 'cm',
              weight: '70',
              measure_unit: 'kg',
            },
            size: 72,
          },
          {
            text: 'ponad 200 cm',
            value: {
              height: '',
              width: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
            },
            size: 88,
          },
          {
            text: 'Dodatki',
            value: {
              height: '0',
              width: '0',
              depth: '0',
              dimensions_unit: 'cm',
              weight: '0',
              measure_unit: 'kg',
              isExtra: true,
            },
            size: 40,
            icon: 'mdi-package-variant-closed',
            tooltip:
              'Dodatki nie wymagają podania wymiarów. Zaliczamy do nich: nogi, wkładki, śrubki, itp.',
          },
        ],
        hard_furniture: [
          {
            text: 'Mała',
            showDimensions: true,
            value: {
              height: '60',
              width: '60',
              depth: '35',
              dimensions_unit: 'cm',
              weight: '25',
              measure_unit: 'kg',
            },
            size: 40,
          },
          {
            text: 'Średnia',
            showDimensions: true,
            value: {
              height: '120',
              width: '120',
              depth: '35',
              dimensions_unit: 'cm',
              weight: '35',
              measure_unit: 'kg',
            },
            size: 56,
          },
          {
            text: 'Duża',
            showDimensions: true,
            value: {
              height: '200',
              width: '160',
              depth: '35',
              dimensions_unit: 'cm',
              weight: '55',
              measure_unit: 'kg',
            },
            size: 72,
          },
          {
            text: 'Inne',
            value: {
              height: '',
              width: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
            },
            size: 88,
          },
        ],
        upholstered_furniture: [
          {
            text: 'Normalna',
            value: {
              height: '',
              width: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
            },
          },
        ],
      },

      resolve: null,
      reject: null,
    }),
    watch: {},
    computed: {
      packageTypes: function () {
        let packagesArray = [];
        switch (this.variantType) {
          case 'chairs':
            packagesArray = this.packageSize.chairs;
            break;
          case 'tables':
            packagesArray = this.packageSize.tables;
            break;
          case 'hard_furniture':
            packagesArray = this.packageSize.hard_furniture;
            break;
          default:
            packagesArray = this.packageSize.upholstered_furniture;
            break;
        }

        return !this.disableExtraPackages
          ? packagesArray
          : packagesArray.filter(elem => !elem.value.isExtra);
      },
    },
    methods: {
      open(variantType, disableExtraPackages = false) {
        this.variantType = variantType;
        this.disableExtraPackages = disableExtraPackages;
        if (this.packageTypes.length == 1)
          return Object.assign(
            {
              package_id: uuidv4(),
              height: '',
              width: '',
              depth: '',
              dimensions_unit: 'cm',
              weight: '',
              measure_unit: 'kg',
            },
            this.packageTypes[0].value
          );

        this.isOpen = true;

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      // eslint-disable-next-line no-unused-vars
      submit(pck, index) {
        this.resolve &&
          this.resolve(
            Object.assign(
              {
                package_id: uuidv4(),
                height: '',
                width: '',
                depth: '',
                dimensions_unit: 'cm',
                weight: '',
                measure_unit: 'kg',
              },
              pck
            )
          );
        this.isOpen = false;
      },
    },
    created() {},
  };
</script>

<style scoped>
  .square-120 {
    width: 120px;
    max-width: 120px;
    height: 120px;
    max-height: 120px;
  }
  .square-100 {
    width: 100px;
    max-width: 100px;
    height: 100px;
    max-height: 100px;
  }
  .square-80 {
    width: 80px;
    max-width: 80px;
    height: 80px;
    max-height: 80px;
  }
  .square-60 {
    width: 60px;
    max-width: 60px;
    height: 60px;
    max-height: 60px;
  }
  .one-liner-text {
    white-space: nowrap;
    display: block;
    overflow: hidden;
  }
</style>
<style></style>
