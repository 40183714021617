<template>
  <v-container class="pa-0">
    <v-form ref="itemForm" v-model="itemForm">
      <v-row>
        <v-col lg="8" md="8" sm="8" cols="12">
          <v-text-field
            v-model="itemCopy.name"
            label="Nazwa przedmiotu"
            :rules="lengthRule(1, 128)"
            maxlength="128"
          ></v-text-field>
        </v-col>

        <v-col lg="4" md="4" sm="4" cols="12" class="pt-3 pb-4">
          <v-select
            v-model="itemCopy.category_id"
            :items="categories"
            label="Kategoria"
            hide-details
            item-text="name"
            item-value="category_id"
          ></v-select>
        </v-col>

        <!-- v-slot extra positions for item -->
      </v-row>

      <v-row v-if="editMode && isAdmin">
        <v-col lg="6" md="6" sm="6" cols="12">
          <v-select
            v-model="itemCopy.item_type"
            :items="item_types"
            label="Typ cennika"
            hide-details
            item-text="name"
            item-value="item_type"
            @change="editItemType"
          ></v-select>
        </v-col>
      </v-row>
    </v-form>

    <v-divider></v-divider>
    <v-divider></v-divider>

    <v-data-table
      :headers="headers"
      :items="itemCopy.variants"
      :single-expand="true"
      :expanded.sync="expanded"
      item-key="index"
      show-expand
      class="elevation-1"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>
            Warianty
            <v-tooltip top max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-2 panel-header-flex-fix" small>
                  mdi-information
                </v-icon>
              </template>
              <span>
                Przedmiot musi zawierać przynajmniej jeden wariant. Warianty to inne wersje tego
                samego przedmiotu (modelu), różniącego się szczegółami technicznymi, dostępnością
                kolorów, sposobem zapakowania, ilością paczek, itp. Każdy nowy lub edytowany (zmiana
                wymiarów, ilości paczek) wymaga wyceny przez administratora (otrzymasz powiadomienie
                -- dzwonek). System umożliwia składanie zleceń transportu dla niewycenionych
                przedmiotów.
              </span>
              <br />
              <span>
                Przykład: `Szafka Apollo` może występować w dwóch wersjach: bez szafek i z szafkami.
                Stworzenie dwóch wariantów dla `Szafka Apollo` pozwala zachować większą
                przejrzystość zlecenia oraz na zdefiniowanie innej ilości paczek oraz innych
                wymiarów, np. każda zapakowana szafka to dodatkowa paczka.
              </span>
            </v-tooltip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>

      <template v-slot:[`item.packages`]="{ item }">
        {{ item.packages.length }}
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div v-if="isAdmin && item.isAccepted">
          <v-edit-dialog :return-value="item.price">
            {{ parseFloat(item.price).toFixed(2) }} zł
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span> Edytuj </span>
            </v-tooltip>
            <template v-slot:input>
              <v-text-field
                v-model="item.price"
                single-line
                label="Cena"
                :rules="float(10, 2, false)"
                type="number"
                maxlength="12"
                min="0"
                dense
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
        <div v-else-if="isAdmin">
          <v-edit-dialog :return-value="item.price">
            {{ item.price ? parseFloat(item.price).toFixed(2) + ' zł' : '' }}
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="error" dark v-bind="attrs" v-on="on">
                  <v-icon> mdi-currency-usd </v-icon>
                </v-btn>
              </template>
              <span> Cena niezweryfikowana przez administratora </span>
            </v-tooltip>
            <template v-slot:input>
              <v-text-field
                v-model="item.price"
                single-line
                label="Cena"
                :rules="float(10, 2, false)"
                type="number"
                maxlength="12"
                min="0"
                dense
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </div>
        <div v-else-if="item.isAccepted">{{ parseFloat(item.price).toFixed(2) }} zł</div>
        <div v-else>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="error" dark v-bind="attrs" v-on="on" icon>
                <v-icon> mdi-currency-usd </v-icon>
              </v-btn>
            </template>
            <span> Cena niezweryfikowana przez administratora </span>
          </v-tooltip>
        </div>
      </template>

      <template v-slot:[`item.data-table-expand`]="{ expand, isExpanded, item }">
        <v-row no-gutters dense v-if="!isExpanded">
          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="removeVariant(item)"
                >
                  <v-icon> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span> Usuń </span>
            </v-tooltip>
          </v-col>

          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="editVariant(expand, isExpanded, item)"
                >
                  <v-icon> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span> Edytuj </span>
            </v-tooltip>
          </v-col>
        </v-row>

        <v-row no-gutters dense v-else>
          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="success"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="acceptVariant(expand, isExpanded, item)"
                >
                  <v-icon> mdi-checkbox-marked-circle </v-icon>
                </v-btn>
              </template>
              <span> Zatwierdź zmiany </span>
            </v-tooltip>
          </v-col>

          <v-col cols="6">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="error"
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="cancelVariant(expand, isExpanded, item)"
                >
                  <v-icon> mdi-cancel </v-icon>
                </v-btn>
              </template>
              <span> Odrzuć zmiany </span>
            </v-tooltip>
          </v-col>
        </v-row>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-form ref="variantForm" v-model="variantForm">
            <v-row class="mx-4 my-2 pa-auto">
              <v-col lg="9" md="9" sm="9" cols="12">
                <v-text-field
                  v-model="item.variant_name"
                  label="Nazwa wariantu"
                  :rules="lengthRule(1, 128)"
                  maxlength="128"
                  dense
                ></v-text-field>
              </v-col>

              <v-col v-if="editMode && isAdmin" lg="3" md="3" sm="3" cols="12">
                <v-select
                  v-model="item.overwrite_type"
                  :items="[{ item_type: null, name: '' }].concat(item_types)"
                  label="Nadpisanie cennika"
                  hide-details
                  item-text="name"
                  item-value="item_type"
                  dense
                  height="26px"
                  @change="editVariantType(item, $event)"
                >
                  <template v-slot:append>
                    <v-tooltip top max-width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on"> mdi-information </v-icon>
                      </template>
                      <span>
                        Można to zastosować w przypadku gdy użytkownik wprowadził warianty
                        przedmiotów, które powinny należeć do innego przedmiotu (wariant powinnien
                        mieć inny typ cennika niż przedmiot w którym się znajduje)
                      </span>
                    </v-tooltip>
                  </template>
                </v-select>
              </v-col>

              <v-col lg="8" md="8" sm="8" cols="12">
                <v-row no-gutters align="center" justify="start">
                  <v-col class="shrink pa-2">
                    <v-chip color="success" ripple @click="colorDialog = !colorDialog">
                      Dodaj wybarwienie
                      <v-icon right small> mdi-plus-circle </v-icon>
                    </v-chip>
                  </v-col>

                  <v-col v-for="(color, index) in item.color" :key="index" class="shrink pa-2">
                    <v-chip close @click:close="item.color.splice(index, 1)">
                      {{ color }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>

              <v-col lg="4" md="4" sm="4" cols="12">
                <v-text-field
                  v-model="item.SKU"
                  label="SKU (opcjonalnie)"
                  :rules="lengthRule(0, 32)"
                  maxlength="32"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>

          <v-form ref="packageForm" v-model="packageForm">
            <v-row class="mx-4 my-2 pa-auto">
              <v-col lg="12" md="12" sm="12" cols="12">
                <v-data-table
                  :headers="packageHeaders"
                  :items="item.packages.filter(elem => !elem.isExtra)"
                  hide-default-footer
                  disable-pagination
                  dense
                >
                  <template v-slot:top>
                    <p class="font-weight-medium">
                      Paczki
                      <v-tooltip top max-width="515">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="ml-2 panel-header-flex-fix" small>
                            mdi-information
                          </v-icon>
                        </template>
                        <span>
                          Wariant musi zawierać przynajmniej jedną paczkę. Podanie ilości i wymiarów
                          paczek jest niezbędne administratorowi do poprawnej wyceny warianty oraz
                          weryfikacji kompletności przedmiotu podczas transportu.
                          Dodanie/usunięcie/edycja paczki wymaga ponownej weryfikacji ceny przez
                          administratora.
                        </span>
                      </v-tooltip>
                    </p>
                  </template>

                  <template v-slot:[`item.height`]="props">
                    <v-edit-dialog :return-value="props.item.height" eager @open="openPackage()">
                      {{ props.item.height }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span> Edytuj </span>
                      </v-tooltip>
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.height"
                          single-line
                          label="Długość"
                          :rules="float(9, 5)"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.width`]="props">
                    <v-edit-dialog :return-value="props.item.width" eager @open="openPackage()">
                      {{ props.item.width }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span> Edytuj </span>
                      </v-tooltip>
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.width"
                          single-line
                          label="Szerokość"
                          :rules="float(9, 5)"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.depth`]="props">
                    <v-edit-dialog :return-value="props.item.depth" eager @open="openPackage()">
                      {{ props.item.depth }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span> Edytuj </span>
                      </v-tooltip>
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.depth"
                          single-line
                          label="Wysokość"
                          :rules="float(9, 5)"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.dimensions_unit`]="props">
                    <v-edit-dialog
                      :return-value="props.item.dimensions_unit"
                      eager
                      @open="openPackage()"
                    >
                      {{ props.item.dimensions_unit }}
                      <template v-slot:input>
                        <v-select
                          v-model="props.item.dimensions_unit"
                          :items="dimensions_unit"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.weight`]="props">
                    <v-edit-dialog :return-value="props.item.weight" eager @open="openPackage()">
                      {{ props.item.weight }}
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon color="primary" x-small v-bind="attrs" v-on="on">
                            <v-icon> mdi-pencil </v-icon>
                          </v-btn>
                        </template>
                        <span> Edytuj </span>
                      </v-tooltip>
                      <template v-slot:input>
                        <v-text-field
                          v-model="props.item.weight"
                          single-line
                          label="Waga"
                          :rules="float(9, 5)"
                          type="number"
                          maxlength="14"
                          min="0"
                          dense
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.measure_unit`]="props">
                    <v-edit-dialog
                      :return-value="props.item.measure_unit"
                      eager
                      @open="openPackage()"
                    >
                      {{ props.item.measure_unit }}
                      <template v-slot:input>
                        <v-select
                          v-model="props.item.measure_unit"
                          :items="measure_unit"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <!-- slot extra packages information -->
                  <template v-slot:[`header.cardboard_boxes`]="{ header }">
                    {{ header.text }}
                    <v-tooltip top max-width="500">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="ml-1" small>
                          mdi-information
                        </v-icon>
                      </template>
                      <span> Czy krzesło jest w kartonie (do dwóch krzeseł w kartonie)? </span>
                    </v-tooltip>
                  </template>

                  <template v-slot:[`item.cardboard_boxes`]="props">
                    <v-edit-dialog
                      :return-value="props.item.cardboard_boxes"
                      eager
                      @open="openPackage()"
                    >
                      {{ props.item.cardboard_boxes ? 'TAK' : 'NIE' }}
                      <template v-slot:input>
                        <v-select
                          v-model="props.item.cardboard_boxes"
                          item-value="value"
                          item-text="text"
                          :items="[
                            { value: true, text: 'TAK' },
                            { value: false, text: 'NIE' },
                          ]"
                          dense
                        ></v-select>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="error"
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          @click="removePackage(item)"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span> Usuń </span>
                    </v-tooltip>
                  </template>
                </v-data-table>

                <v-data-table
                  v-show="item.packages.filter(elem => elem.isExtra).length"
                  :headers="[
                    {
                      text: 'LP',
                      value: 'index',
                      sortable: false,
                      width: 48,
                      cellClass: 'cell-48',
                    },
                    { text: 'Info', value: 'info', sortable: false },
                    {
                      text: '',
                      value: 'actions',
                      sortable: false,
                      width: 36,
                      cellClass: 'cell-36',
                    },
                  ]"
                  :items="item.packages.filter(elem => elem.isExtra)"
                  hide-default-header
                  hide-default-footer
                  disable-pagination
                  dense
                >
                  <template v-slot:[`item.info`]> Paczka nie wymaga podania wymiarów </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          color="error"
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          @click="removePackage(item)"
                        >
                          <v-icon> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span> Usuń </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>

              <v-col lg="12" md="12" sm="12" cols="12" align="center">
                <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="blue-grey"
                      class="grey--text text--darken-4 mx-auto"
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="addPackage(item)"
                    >
                      <v-icon> mdi-plus-circle </v-icon>
                    </v-btn>
                  </template>
                  <span> Dodaj paczkę </span>
                </v-tooltip> -->

                <v-btn dark small @click="addPackage(item)">
                  Dodaj paczkę
                  <v-icon right> mdi-plus-circle </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </td>
      </template>
    </v-data-table>

    <v-row class="mx-4 my-4 pa-auto" v-if="showAddVariantBtn">
      <v-btn block @click="addVariant()" x-large dark color="primary" class="mx-auto">
        <v-icon left> mdi-plus </v-icon> Dodaj wariant
      </v-btn>
    </v-row>

    <!-- add color dialog -->
    <SimpleDialogForm
      v-model="colorDialog"
      label="Wybarwienie"
      :rules="lengthRule(1, 64)"
      :maxlength="64"
      @submit="addColor"
    />

    <!-- confirm dialog -->
    <ConfirmDialog ref="confirmDialog"></ConfirmDialog>

    <!-- snackbar -->
    <InfoSnackBar v-model="info"></InfoSnackBar>

    <!-- choose package dialog -->
    <ChoosePackageSizeDialog ref="choosePackage"></ChoosePackageSizeDialog>
  </v-container>
</template>

<script>
  // import { v4 as uuidv4 } from 'uuid';
  import ConfirmDialog from '../Confirm.vue';
  import SimpleDialogForm from '../SimpleDialogForm.vue';
  import InfoSnackBar from '@/components/InfoSnackBar.vue';
  import ChoosePackageSizeDialog from '@/components/PriceTable/ChoosePackageSizeDialog.vue';

  export default {
    name: 'AddEditItemComponent',
    components: {
      ConfirmDialog,
      SimpleDialogForm,
      InfoSnackBar,
      ChoosePackageSizeDialog,
    },
    props: {
      item: {
        type: Object,
      },
      categories: {
        type: Array,
        required: true,
      },
      item_type: {
        type: String,
        default: 'hard_furniture',
      },
      editMode: {
        type: Boolean,
        default: false,
      },
      singleVariant: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      itemForm: false,
      variantForm: false,
      packageForm: false,

      colorDialog: false,

      itemCopy: {},
      editingVariantCopy: null,

      item_types: [
        {
          item_type: 'hard_furniture',
          name: 'Meble w paczkach',
        },
        {
          item_type: 'upholstered_furniture',
          // name: 'Meble tapicerowane',
          name: 'Inne',
        },
        {
          item_type: 'chairs',
          name: 'Krzesła',
        },
        {
          item_type: 'tables',
          name: 'Stoły',
        },
      ],

      dimensions_unit: ['m', 'dm', 'cm'],
      measure_unit: ['kg'], // ['kg', 'g'],
      package_unit: ['szt'],

      expanded: [],
      headers: [
        {
          text: 'LP',
          value: 'index',
          sortable: false,
        },
        {
          text: 'Nazwa wariantu',
          value: 'variant_name',
          sortable: false,
        },
        {
          text: 'SKU',
          value: 'SKU',
          sortable: false,
        },
        {
          text: 'Ilość p.',
          value: 'packages',
          sortable: false,
        },
        {
          text: 'Cena',
          value: 'price',
          sortable: false,
          width: '186px',
        },
        {
          text: '',
          value: 'data-table-expand',
          sortable: false,
          width: '80px',
        },
      ],

      info: null,
    }),
    computed: {
      isAdmin: function () {
        return this.$store.getters.isAdmin;
      },
      packageHeaders: function () {
        const tmp = [
          {
            text: 'LP',
            value: 'index',
            sortable: false,
            width: 48,
          },
          {
            text: 'Długość',
            value: 'height',
            sortable: false,
          },
          {
            text: 'Szerokość',
            value: 'width',
            sortable: false,
          },
          {
            text: 'Wysokość',
            value: 'depth',
            sortable: false,
          },
          {
            text: 'J. wymiaru',
            value: 'dimensions_unit',
            sortable: false,
          },
          {
            text: 'Waga',
            value: 'weight',
            sortable: false,
          },
          {
            text: 'J. wagi',
            value: 'measure_unit',
            sortable: false,
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            width: 36,
          },
        ];

        if (this.currentVariantType === 'chairs')
          tmp.splice(7, 0, {
            text: 'Karton',
            value: 'cardboard_boxes',
            sortable: false,
            width: '92px',
          });

        return tmp;
      },
      currentVariantType: function () {
        if (this.expanded.length && this.expanded[0].overwrite_type != null)
          return this.expanded[0].overwrite_type;
        else return this.itemCopy.item_type || this.item_type;
      },
      isValid: function () {
        return this.itemForm && this.variantForm && this.packageForm;
      },

      showAddVariantBtn: function () {
        return !this.expanded.length && (!this.singleVariant || !this.itemCopy?.variants?.length);
      },
    },
    watch: {
      item: function () {
        this.initItem();
      },
      item_type: function () {
        this.initItem();
      },
    },
    methods: {
      initItem: async function () {
        const tmp = this.item
          ? JSON.parse(JSON.stringify(this.item))
          : {
              name: '',
              category_id: null,
              item_type: this.item_type,
              isAccepted: false,
              variants: [],
            };

        let cnt_v = 1;
        for (let i = 0; i < tmp.variants.length; i++) {
          let cnt_p = 1;
          for (let j = 0; j < tmp.variants[i].packages.length; j++) {
            tmp.variants[i].packages[j].index = cnt_p++;
          }
          tmp.variants[i].index = cnt_v++;
        }

        this.itemCopy = tmp;
        if (!this.item) {
          const newVariant = await this.getEmptyVariant();
          this.itemCopy.variants = [newVariant];
          this.expanded = [newVariant];
        } else {
          this.expanded = [];
          this.itemForm = true;
          this.variantForm = true;
          this.packageForm = true;
        }
      },
      getEmptyPackage: async function (isFirst = false) {
        // const tmp = {
        //   package_id: uuidv4(),
        //   height: '',
        //   width: '',
        //   depth: '',
        //   dimensions_unit: 'cm',
        //   weight: '',
        //   measure_unit: 'kg',
        // };
        const tmp = await this.$refs.choosePackage.open(this.currentVariantType, isFirst);
        if (this.currentVariantType === 'chairs') tmp.cardboard_boxes = true;

        return tmp;
      },
      getEmptyVariant: async function () {
        const pck = await this.getEmptyPackage(true);
        pck.index = 1;

        return {
          variant_name: 'Nowy wariant',
          SKU: '',
          color: [],
          packages: [pck],
          overwrite_type: null,
          price: '',
          isAccepted: false,
        };
      },
      float: function (to_the_left, to_the_right, required = true, positive = true, notZero = true) {
        let rules = [];

        let left = '0';
        if (to_the_left > 0) {
          left = `-?(0|([1-9][0-9]{0,${to_the_left - 1}}))`;
        }

        let right = '';
        if (to_the_right > 0) {
          right = `((\\.|,)[0-9]{0,${to_the_right - 1}}[1-9])?`;
        }

        if (required) {
          rules.push(v => !!v || 'Pole jest wymagane');
          rules.push(v => new RegExp(`^(${left + right})$`).test(v) || 'Zły format liczby');
        } else {
          rules.push(v => new RegExp(`^$|^(${left + right})$`).test(v) || 'Zły format liczby');
        }

        if (positive) {
          rules.push(v => v >= 0 || 'Pole musi być dodatnią liczbą');
        }

        if (notZero) {
          if (required) {
            rules.push(v => v != 0 || 'Pole musi być różne od zera');
          } else {
            rules.push(v => v == '' || v != 0 || 'Pole musi być różne od zera');
          }
        }

        return rules;
      },
      lengthRule: function (min_length, max_length) {
        return [
          v =>
            new RegExp(`^.{${min_length},${max_length}}$`).test(v) ||
            `Pole musi zawierac od ${min_length} do ${max_length} znaków`,
        ];
      },
      addColor: function (color) {
        this.expanded[0].color.push(color);
      },
      addPackage: async function (item) {
        if (!this.packageForm)
          return (this.info = {
            message: `Najpierw uzupełnij poprawnie pozostałe paczki zanim dodasz następną!`,
            status: 'error',
          });

        const emptyPackage = await this.getEmptyPackage(); // !this.expanded[0]?.packages.filter(elem => !elem.isExtra).length
        if (emptyPackage.isExtra) {
          emptyPackage.index = item.packages.length + 1;
          item.packages.push(emptyPackage);
        } else {
          const firstExtraIndex =
            item.packages.find(elem => elem.isExtra)?.index - 1 || item.packages.length;
          item.packages.splice(firstExtraIndex, 0, emptyPackage);
          for (let index = 0; index < item.packages.length; index++)
            item.packages[index].index = index + 1;
        }
      },
      removePackage: async function (item, dialog = true) {
        if (
          dialog &&
          !(await this.$refs.confirmDialog.open(
            'Usuń paczkę',
            `Czy jesteś pewny, że chcesz usunąć paczkę nr ${item.index}?`,
            { color: 'red' }
          ))
        )
          return;

        const variant = this.expanded[0];

        if (!item.isExtra && variant.packages.filter(elem => !elem.isExtra).length == 1)
          return (this.info = {
            message: 'Wariant musi zawierać przynajmniej jedną paczkę z wymiarami',
            status: 'error',
          });

        const index = variant.packages.findIndex(obj => obj.index == item.index);
        if (index !== -1) {
          variant.packages.splice(index, 1);

          // fix indexes
          let cnt = 1;
          for (let index = 0; index < variant.packages.length; index++) {
            variant.packages[index].index = cnt++;
          }

          if (!variant.packages.length) {
            const emptyPackage = await this.getEmptyPackage();
            emptyPackage.index = variant.packages.length + 1;
            variant.packages = [emptyPackage];

            return (this.info = {
              message: 'Wariant musi zawierać przynajmniej jedną paczkę',
              status: 'error',
            });
          }
        }
      },
      addVariant: async function () {
        if (this.expanded.length)
          return (this.info = {
            message: 'Skończ edytować obecny wariant zanim dodasz następny',
            status: 'error',
          });

        const tmp = await this.getEmptyVariant();
        tmp.index = this.itemCopy.variants.length + 1;
        this.editingVariantCopy = JSON.parse(JSON.stringify(tmp));

        this.itemCopy.variants.push(tmp);
        this.expanded = [tmp];

        // this.$nextTick(()  => {
        //   this.$refs.formVariant.validate();
        // });
      },
      editVariant: function (expand, isExpanded, item) {
        if (this.expanded.length)
          return (this.info = {
            message: 'Najpierw skończ edytować obecny wariant',
            status: 'error',
          });

        this.editingVariantCopy = JSON.parse(JSON.stringify(item));
        // this.counterPackage = item.packages.length + 1;
        expand(!isExpanded);
      },
      removeVariant: async function (item, dialog = true) {
        if (this.expanded.length)
          return (this.info = {
            message: 'Najpierw skończ edytować obecny wariant',
            status: 'error',
          });

        if (
          dialog &&
          !(await this.$refs.confirmDialog.open(
            'Usuń wariant',
            `Czy jesteś pewny, że chcesz usunąć wariant nr ${item.index}?`,
            { color: 'red' }
          ))
        )
          return;

        const index = this.itemCopy.variants.findIndex(obj => obj.index == item.index);
        if (index !== -1) {
          this.itemCopy.variants.splice(index, 1);

          //if (removedVariant.item_variant_id) console.log('Usuwam istniejacy');

          // fix indexes
          let cnt = 1;
          for (let index = 0; index < this.itemCopy.variants.length; index++) {
            this.itemCopy.variants[index].index = cnt++;
          }

          // fix if not more variants
          if (!this.itemCopy.variants.length) {
            const emptyVariant = await this.getEmptyVariant();
            emptyVariant.index = 1;
            this.itemCopy.variants = [emptyVariant];
            this.expanded = [emptyVariant];

            this.$nextTick(() => {
              this.$refs.itemForm.validate();
            });
            return (this.info = {
              message: 'Przedmiot musi zawierać przynajmniej jeden wariant',
              status: 'error',
            });
          }
        }

        this.$nextTick(() => {
          this.$refs.packageForm ? this.$refs.packageForm.validate() : (this.packageForm = true);
        });
      },
      acceptVariant: function (expand, isExpanded, item) {
        if (!this.packageForm)
          return (this.info = {
            message: `Najpierw uzupełnij poprawnie paczki dla edytowanego wariantu!`,
            status: 'error',
          });

        if (!this.packageForm)
          return (this.info = {
            message: `Najpierw uzupełnij poprawnie wariant!`,
            status: 'error',
          });

        // lock this variant (to not remove it on next edit)
        if (!item.item_variant_id) item.item_variant_id = ' ';

        this.editedVariant = null;
        expand(!isExpanded);
      },
      cancelVariant: function (expand, isExpanded, item) {
        if (item.item_variant_id) {
          Object.assign(item, this.editingVariantCopy);
          this.editingVariantCopy = null;
          expand(!isExpanded);

          this.$nextTick(() => {
            this.$refs.packageForm ? this.$refs.packageForm.validate() : (this.packageForm = true);
          });
        } else {
          this.expanded = [];
          this.removeVariant(item, false);
        }
      },
      openPackage: function () {
        this.$nextTick(() => {
          this.$refs.packageForm.validate();
        });
      },
      infoSnackBar(info) {
        this.info = info;
      },
      editItemType: function (itemType) {
        // fix packages
        this.itemCopy.variants.forEach(variant => {
          if (variant.overwrite_type == null) {
            if (itemType !== 'tables')
              variant.packages.filter(elem => elem.isExtra).forEach(elem => delete elem.isExtra);
            // variant.packages = variant.packages.filter(elem => !elem.isExtra);

            variant.packages.forEach(elem => {
              if (itemType === 'chairs')
                this.$set(elem, 'cardboard_boxes', elem.cardboard_boxes ?? true);
              else delete elem.cardboard_boxes;
            });
          }
        });
      },
      editVariantType: function (variant, itemType) {
        // fix packages
        if (itemType !== 'tables' && this.currentVariantType !== 'tables')
          variant.packages.filter(elem => elem.isExtra).forEach(elem => delete elem.isExtra);
        // variant.packages = variant.packages.filter(elem => !elem.isExtra);

        variant.packages.forEach(elem => {
          if (itemType === 'chairs' || this.currentVariantType === 'chairs')
            this.$set(elem, 'cardboard_boxes', elem.cardboard_boxes ?? true);
          else delete elem.cardboard_boxes;
        });
      },
    },
    mounted() {
      this.initItem();
    },
    created() {},
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
  div.v-select__slot div.v-input__append-inner {
    align-self: auto;
  }
  table > tbody > tr.v-data-table__expanded.v-data-table__expanded__row {
    background: #eeeeee !important;
  }
  table > thead > tr > th {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  table > tbody > tr > td {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .v-application--is-ltr .v-text-field .v-input__append-inner {
    margin-top: 0px !important;
  }
  .cell-48 {
    width: 48px;
    min-width: 48px;
  }
  .cell-36 {
    width: 36px;
    min-width: 36px;
  }
</style>
