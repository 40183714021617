<template>
  <v-dialog v-model="isActive" persistent :width="width" @keydown.enter.prevent>
    <v-card>
      <v-card-text class="pt-5 pb-0">
        <v-form v-model="isFormValide">
          <v-text-field
            ref="focus"
            v-model="field"
            :label="label"
            :rules="rules"
            :type="type"
            :counter="maxlength ? true : false"
            :maxlength="maxlength"
            outlined
            dense
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <slot name="buttons" v-bind="{ submit, cancel }">
          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            outlined
            v-tilt
            @click="cancel"
          >
            Anuluj
          </v-btn>

          <v-spacer />

          <v-btn
            color="amber accent-3"
            class="grey--text text--darken-4"
            v-tilt
            :disabled="!isFormValide"
            @click="submit"
          >
            Dodaj
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SimpleDialogForm',
    components: {},
    props: {
      value: {
        type: Boolean,
        default: true,
      },
      width: {
        type: String,
        default: '400px',
      },
      type: {
        type: String,
        default: 'text',
      },
      label: {
        type: String,
        default: 'Field',
      },
      rules: {
        type: Array,
        default: () => [],
      },
      maxlength: {
        type: [String, Number],
        default: '',
      },
    },
    data: () => ({
      field: '',
      isFormValide: false,
    }),
    methods: {
      submit: function () {
        this.$emit('submit', this.field);
        this.cancel();
      },
      cancel: function () {
        this.$emit('input', false);
        this.field = '';
      },
    },
    watch: {
      isActive: function (newVal) {
        if (newVal === true)
          requestAnimationFrame(() => {
            return this.$refs.focus instanceof Array
              ? this.$refs.focus.forEach(elem => elem.focus())
              : this.$refs.focus.focus();
          });
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set() {
          this.cancel();
        },
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style>
</style>
