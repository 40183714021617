<template>
  <v-dialog v-model="isActive" persistent :width="width">
    <v-card>
      <v-toolbar flat color="black" dark>
        <v-toolbar-title v-if="!item"> {{ stepsData[step].title }} </v-toolbar-title>
        <v-toolbar-title v-else> Edytuj przedmiot wraz z wariantami </v-toolbar-title>
        <v-spacer />
      </v-toolbar>

      <v-card-text class="py-5 grey--text text--darken-4">
        <v-row
          v-for="(item, index) in stepsData[step].types"
          :key="index"
          justify="center"
          align="center"
        >
          <v-col cols="12" v-if="item.type == null || permissions[item.type]">
            <v-btn block dark x-large @click="thisApplier(item.next, $event)">
              {{ item.name }}
            </v-btn>
          </v-col>
        </v-row>

        <v-row v-if="stepsData[step].item_type" justify="center" align="center">
          <AddEditItemComponent
            ref="addEditItem"
            :item="item"
            :editMode="!!item"
            :singleVariant="singleVariant"
            :item_type="stepsData[step].item_type"
            :categories="categories"
          ></AddEditItemComponent>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="item"
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="thisApplier(stepsData[0].back, $event)"
        >
          {{ stepsData[0].backText }}
        </v-btn>

        <v-btn
          v-else-if="stepsData[step].back"
          color="amber accent-3"
          class="grey--text text--darken-4"
          outlined
          v-tilt
          x-large
          @click="thisApplier(stepsData[step].back, $event)"
        >
          {{ stepsData[step].backText }}
        </v-btn>

        <v-spacer />

        <v-btn
          v-if="stepsData[step].next"
          color="amber accent-3"
          class="grey--text text--darken-4"
          v-tilt
          x-large
          @click="thisApplier(stepsData[step].next, $event)"
        >
          {{ !!item ? 'Edytuj' : stepsData[step].nextText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import AddEditItemComponent from './AddEditItemComponent.vue';

  export default {
    name: 'AddEditItemDialog',
    components: {
      AddEditItemComponent,
    },
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      item: {
        type: Object,
      },
      categories: {
        type: Array,
        default: () => [{ name: 'Ogólna', category_id: null }],
      },
      permissions: {
        type: Object,
        default: () => ({
          hard_furniture: 1,
          upholstered_furniture: 1,
          chairs: 1,
          tables: 1,
        }),
      },
      width: {
        type: String,
        default: '820px',
      },
      singleVariant: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      step: 0,
      stepsData: [
        {
          title: 'Wybierz typ przedmiotu',
          types: [
            {
              type: 'chairs',
              name: 'Krzesło',
              next: function () {
                this.step = 1;
                // this.$refs.addEditItem.initItem();
              },
            },
            {
              type: 'tables',
              name: 'Stół',
              next: function () {
                this.step = 2;
              },
            },
            {
              type: 'hard_furniture',
              name: 'Meble paczkowane',
              next: function () {
                this.step = 3;
              },
            },
            // {
            //   type: 'upholstered_furniture',
            //   name: 'Meble tapicerowane',
            //   next: function () {
            //     this.step = 4;
            //   },
            // },
            {
              name: 'Inne',
              next: function () {
                this.step = 5;
              },
            },
          ],
          back: function () {
            this.isActive = false;
          },
          backText: 'Anuluj',
        },
        {
          title: 'Dodaj krzesło wraz z jego wariantami',
          item_type: 'chairs',
          back: function () {
            this.step = 0;
          },
          backText: 'Wstecz',
          next: function () {
            this.submit();
          },
          nextText: 'Dodaj',
        },
        {
          title: 'Dodaj stół wraz z jego wariantami',
          item_type: 'tables',
          back: function () {
            this.step = 0;
          },
          backText: 'Wstecz',
          next: function () {
            this.submit();
          },
          nextText: 'Dodaj',
        },
        {
          title: 'Dodaj mebel paczkowany wraz z jego wariantami',
          item_type: 'hard_furniture',
          back: function () {
            this.step = 0;
          },
          backText: 'Wstecz',
          next: function () {
            this.submit();
          },
          nextText: 'Dodaj',
        },
        {
          title: 'Dodaj mebel tapicerowany wraz z jego wariantami',
          item_type: 'upholstered_furniture',
          back: function () {
            this.step = 0;
          },
          backText: 'Wstecz',
          next: function () {
            this.submit();
          },
          nextText: 'Dodaj',
        },
        {
          title: 'Dodaj inny przedmiot wraz z jego wariantami',
          item_type: 'upholstered_furniture',
          back: function () {
            this.step = 0;
          },
          backText: 'Wstecz',
          next: function () {
            this.submit();
          },
          nextText: 'Dodaj',
        },
      ],
    }),
    watch: {
      item: function () {
        this.$nextTick(() => {
          this.stepSwitcher();
        });
      },
      value: function (newValue, oldValue) {
        if (newValue === false && oldValue === true) this.step = 0;
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          if (val === false) this.step = 0;
          this.$emit('input', val);
          // this.$nextTick(() => {
          //   if (this.item) this.stepSwitcher();
          //   else this.step = 0;
          // });
        },
      },
    },
    methods: {
      stepSwitcher: function () {
        if (this.item && this.item.item_type)
          switch (this.item.item_type) {
            case 'hard_furniture':
              this.step = 3;
              break;
            case 'upholstered_furniture':
              this.step = 4;
              break;
            case 'chairs':
              this.step = 1;
              break;
            case 'tables':
              this.step = 2;
              break;
            case 'others':
              this.step = 5;
              break;
            default:
              break;
          }
      },
      thisApplier: function (func, ...args) {
        func.apply(this, args);
      },
      submit: function () {
        if (!this.$refs.addEditItem.isValid)
          return this.$refs.addEditItem.infoSnackBar({
            message: `Uzupełnij wszystkie dane i zatwierdź warianty`,
            status: 'error',
          });

        this.$emit('submit', this.$refs.addEditItem.itemCopy);
      },
    },
    created() {
      this.stepSwitcher();
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
  .v-btn--pretend--disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
</style>
<style></style>
